<template>
  <main class="management-hero">
    <section>
      <div>
        <h1>Management</h1>
        <p>
          Our organization intends to use seafoods to solve poverty and sustain
          livelihood; Make available quality seafoods in Africa. We produce and
          supply seafood to Hotels, restaurants, Private.
        </p>
        <div class="mt-4">
            <button class="button-secondary">
              <a href="/contact">
                <span>Chat with Sales</span>
              </a>
            </button>
          </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {},
  computed: {},
};
</script>

<style scoped>
/* start of hero */
.management-hero {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      94.99deg,
      rgba(0, 0, 0, 0.7) 12.88%,
      rgba(0, 0, 0, 0) 130.51%
    ),
    url(../../assets/images/management/hero.png);
  display: grid;
  align-items: center;
}
.management-hero h1 {
  font-family: "Bangla MN";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  color: #ffffff;
}

.management-hero p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  width: 500px;
  color: #ffffff;
}

@media (max-width: 768px) {
  .management-hero h1 {
    font-size: 30px;
  }
  .management-hero p {
    width: 100%;
  }
}
</style>
