<template>
  <hero />
  <executives/>
</template>

<script>
import hero from "./hero";
import executives from "./executives";

export default {
  components: {
    hero,
    executives
  },
};
</script>
