<template>
  <main class="executives">
    <section>
      <div class="mt-5">
        <div class="heading">
          <h1 class="center">Board of Executives</h1>
          <p>
            Our organization intends to use seafoods to solve poverty and
            sustain livelihood; Make available quality seafoods in Africa. We
            produce and supply seafood to Hotels, restaurants, Private.
          </p>
        </div>

        <div class="executive-grid mt-5 mb-5">
          <div class="executive-grid-item" v-for="i in 4">
            <img src="@/assets/images/avatar.png" />
            <div class="overlay">
              <div>
                <h1>Lydia Lorem</h1>
                <h2>Chief Executive Officer</h2>
                <p>@lydialorem</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {},
  computed: {},
};
</script>

<style scoped>
.heading {
  display: grid;
  place-items: center;
  height: auto;
  width: 563px;
  margin: 0 auto;
}

.heading h1 {
  font-family: "Bangla MN";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 55px;

  color: #000000;
}
.heading p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */

  text-align: center;
}

@media (max-width: 768px) {
  .heading {
    width: 100%;
  }

  .heading h1 {
    font-size: 30px;
  }
}

.executive-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.executive-grid-item {
  width: 100%;
  position: relative;
  height: 400px;
  border-radius: 5px;
}

.executive-grid-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.executive-grid-item .overlay {
  position: absolute;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  border-radius: 5px;
  padding: 30px;
}

.executive-grid-item .overlay > div{ 
  position: absolute;
  bottom: 40px;
}

@media (max-width: 768px) {
  .executive-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
}

.executive-grid-item .overlay h1 {
  font-family: "Bangla MN";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 55px;
  color: white;
}
.executive-grid-item .overlay h2 {
  font-family: "Bangla MN";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  color: white;
}
.executive-grid-item .overlay p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  color: white;
}
</style>
